/* Widget Container */
.widget-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 2rem;
}

/* Widget Title */
.widget-title {
  font-size: 1.5rem;
  margin-bottom: 20px;
  text-align: center;
}

/* Widget Input */
.widget-input {
  width: 95%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
}

/* Widget Button */
.widget-button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.widget-button:hover {
  background-color: #0056b3;
}

/* Loading Indicator */
.widget-loading {
  text-align: center;
  margin-top: 20px;
}

/* Result Display */
.widget-result {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  overflow: auto;
}

/* Responsive Styles for Mobile Devices */
@media (max-width: 768px) {
  .widget-container {
    max-width: 100%;
  }
  .widget-title {
    font-size: 1.2rem;
  }
  .widget-input,
  .widget-button {
    width: 100%;
  }
}
